<template>
  <div class="row">
    <div class="col-md-12">
      <div id="filter-card" class="d-flex justify-content-between items-baseline w-100 mb-2"
           style="margin-bottom: -5px;">
        <div>
          <span class="svg-icon back-button-color svg-icon-2x cursor-pointer mr-2" @click="$router.go(-1)">
          <i class="fa fa-chevron-left"></i>
        </span>
          <h3 class="d-inline-block" id="page-title">{{ id ? $t('time_sheet.time_sheet_edit') : $t('time_sheet.time_sheet_create') }}</h3>
        </div>
      </div>
    </div>
    <div class="col-md-12">
      <dashboard-box class="bg-white" id="mainDiv" v-if="authUser">
        <template slot="preview">
          <div class="p-2 d-flex">

            <div class="flex-grow-1 row p-3">
              <div class="col-12 row">
                <div class="col-3 text-lg">
                  <span class="font-weight-boldest">{{ $t('time_sheet.new_date') }} :</span> <br> 
                  <span v-if="id">
                    {{ form.date  }}
                  </span>
                  <span v-else>
                    {{ today | momentDateFormat }}
                  </span>
                </div>
                <div class="col-3 text-lg">
                  <span class="font-weight-boldest">{{ $t('time_sheet.collection_number') }} :</span> <br>
                  {{ form.collection_number || '-' }}
                </div>

                <div class="col-3">
                  <custom-multi-select
                      class="font-weight-bolder"
                      :title="$t('time_sheet.supplier_company')"
                      :helperText="$t('time_sheet.new_please_enter')"
                      :required="true"
                      :options="supplierCompanyOptions"
                      :model.sync="form.supplier_company_id"
                      :max="1"
                      name="supplier_company_id"
                      :not-list="true"
                      :is-inline="false"
                  >
                  </custom-multi-select>
                </div>

                <div class="col-3 text-lg d-flex justify-content-center">
                  <div class="">
                    <img :src="form.image_link === null ? getIconByKey('general.no-image') : form.image_link"
                         id="image_holder"
                         @click="getOriginalImage"
                         class="w-75px h-75px d-inline-block object-cover"
                         style="border-radius: 10px">
                    <div class="w-20px h-20px ml-8" @click="openUploadDocumentModal">
                      <span  v-html="getIconByKey('icons.waybill.waybill_entry', {
                       class: 'w-30px h-30px d-inline-block object-fill cursor-pointer'
                      })">
                      </span>
                    </div>
                  </div>
                </div>

                <div class="col-3">
                  <text-input
                      class="font-weight-bolder"
                      :title="$t('time_sheet.reference_code')"
                      :helper-text="$t('time_sheet.reference_code')"
                      name="user_description"
                      label-class="font-weight-bolder text-xl"
                      :model.sync="form.reference_code"
                      :is-inline="false"
                  ></text-input>
                </div>

                <div class="col-3">
                  <text-input
                      class="font-weight-bolder"
                      :title="$t('time_sheet.responsible_person')"
                      :helper-text="$t('time_sheet.new_please_enter')"
                      name="responsible_person"
                      :model.sync="form.responsible_person"
                      label-class="font-weight-bolder text-xl"
                      :is-inline="false"
                  ></text-input>
                </div>

                <div class="col-3">
                  <div class="col-12 mt-3">
                    <div class="font-weight-boldest mb-2">
                      <label class="col-form-label-sm" for="time_sheet_description">  {{ $t('time_sheet.description') }}</label>

                    </div>
                    <div class="navy-blue-border-1px px-3 py-2 border-15px">
                    <textarea id="time_sheet_description"
                              v-model="form.description" :placeholder="$t('time_sheet.please_enter_description')"
                              class="w-100 outline-none" name="order_description"
                              rows="2">
                    </textarea>
                    </div>
                  </div>
                </div>
                <div class="col-3">
                  <div class="cursor-pointer mt-3" v-if="form.id" @click="$modal.show('open-qr-code-modal')">
                    <q-r-code
                        :code="form.qr_code" :size="50" :value="form.pdf_link"
                        level="H"
                    ></q-r-code>
                  </div>
                </div>


              </div>
              <div class="col-12 row">
              </div>
              <div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="col-12 p-0 m-0 text-primary">
              <h4 class="d-block text-center">
                {{ $t('time_sheet.fabric_informations') }}
              </h4>
            </div>
            <div class="w-100 border-15px navy-blue-border-1px row mx-0">
              <div class="col-12 d-flex navy-blue-border-bottom-1px">
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  {{ $t('time_sheet.composition') }}
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  {{ $t('time_sheet.quality') }}
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  {{ $t('time_sheet.width') }}
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg py-2">
                  {{ $t('time_sheet.weight') }}
                </div>
              </div>
              <div class="col-12 d-flex">
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  <input
                      type="text"
                      class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                      :placeholder="$t('general.please_enter')"
                      v-model="form.composition"
                  >
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  <input
                      type="text"
                      class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                      :placeholder="$t('general.please_enter')"
                      v-model="form.quality"
                  >
                </div>
                <div class="flex-1 d-flex justify-content-center items-center text-lg navy-blue-border-right-1px py-2">
                  <div class="w-100">
                    <input
                        type="text"
                        class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                        :placeholder="$t('general.please_enter')"
                        v-model="form.width"
                    >
                  </div>
                </div>

                <div class="flex-1 d-flex justify-content-center items-center text-lg py-2">
                  <input
                      type="text"
                      class="flex-grow-1 text-sm py-1 px-1 text-center w-100"
                      :placeholder="$t('general.please_enter')"
                      v-model="form.weight"
                  >
                </div>
              </div>
            </div>
          </div>

          <div class="d-flex pb-0">
            <div class="col flex-grow-1 p-3">
              <div v-if="!form.is_submitted" class="flex-grow-1 justify-content-center row">
                <div class="d-flex justify-content-center mt-3">
                  <button-with-icon
                      v-if="$route.query.redirectName == null &&isUserGranted('TimeSheet', ['create'], false)"
                      @onClick="submitForm"
                      :icon-name="null"
                      class="text-primary mr-3"
                      :disabled="!isFormValid || isSubmitting"
                      size="lg"
                      :text="$t('general.save')"
                  ></button-with-icon>
                </div>
              </div>
            </div>
          </div>
          <template v-if="form.is_submitted">
            <div class="d-flex justify-content-center mt-3">
              <button-with-icon
                  v-if="isUserGranted('TimeSheet', ['update'], false)"
                  @onClick="submitForm"
                  icon-name=""
                  class="text-primary mr-3"
                  size="lg"
                  :text="$t('general.update').toUpperCase()"
              ></button-with-icon>

              <button-with-icon
                  v-if="isUserGranted('TimeSheet', ['delete'], false)"
                  @onClick="deleteItem"
                  icon-name=""
                  class="text-primary mr-3"
                  size="lg"
                  :text="$t('general.delete').toUpperCase()"
              ></button-with-icon>

              <button-with-icon
                  @onClick="openInNewTab(form.pdf_link)"
                  :icon-name="null"
                  class="text-primary mr-3"
                  size="lg"
                  :text="$t('general.print_tag').toUpperCase()"
              ></button-with-icon>

            </div>
          </template>
        </template>

      </dashboard-box>
    </div>

    <modal :adaptive="true" height="auto" name="open-qr-code-modal" width="750" v-if="form.id">
      <div class="card card-custom">
        <div class="card-body card-body-scroll d-flex justify-content-center mt-5">
          <q-r-code
              :code="form.qr_code" :font-classes="'text-lg font-weight-boldest'" :size="300"
              :value="form.pdf_link"
              level="H"
          ></q-r-code>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3"
                  type="button"
                  @click="closeModalByName('open-qr-code-modal')">{{ $t('editSample.close') }}
          </button>
        </div>
      </div>
    </modal>


    <modal :adaptive="true" height="auto" name="upload-new-image" width="750">
      <div class="card card-custom">
        <div class="card-body card-body-scroll">
          <photo-recorder
              :model.sync="form.image"></photo-recorder>
          <file-input
              ref="imageFileInput"
              :model.sync="form.image"
              :title="$t('editSample.image')"
              accept="image/*"
              name="content-image">
            <template v-slot:inputDesign>
              <div class="w-100 d-flex justify-content-center" @click="$refs.imageFileInput.openFileInput()">
                <span v-html="getIconByKey('icons.meeting.select-from-file', {
                      class: 'w-75px h-75px d-inline-block object-fill cursor-pointer'
                })">
                </span>
              </div>
            </template>
          </file-input>
        </div>
        <div class="card-footer d-flex justify-content-end">
          <button class="btn btn-outline-danger font-weight-bold cursor-pointer mr-3 btn-pill"
                  type="button"
                  @click="cancelDocumentUpload">
            {{ $t('editSample.close') }}
          </button>
          <button class="btn btn-sm btn-primary font-weight-bold cursor-pointer btn-pill"
                  type="button"
                  @click="closeUploadDocumentModal">{{ $t('editSample.save') }}
          </button>
        </div>
      </div>
    </modal>

    <CoolLightBox
        :items="imageLinks"
        :index="imageIndex"
        @close="imageIndex = null">
    </CoolLightBox>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {mapMutations, mapGetters} from 'vuex'
import $ from 'jquery';
import {
  CREATE_ITEM,
  UPDATE_ITEM_BY_ID,
  ERROR,
  GET_ITEM_DETAIL_BY_ID,
  GET_ITEMS as REST_GET_ITEM,
  GET_ITEMS,
  DELETE_ITEM_BY_ID,
  LOADING
} from "@/core/services/store/REST.module";
import NumberInput from "@/assets/components/inputs/NumberInput";
import InputFilter from "@/assets/components/filters/InputFilter";
import TextInput from "@/assets/components/inputs/TextInput";
import DatePickerInput from "@/assets/components/inputs/DatePickerInput";
import MultipleSelectInput from "@/assets/components/inputs/MultipleSelectInput";
import SelectInput from "@/assets/components/inputs/SelectInput";
import CustomMultiSelect from "@/assets/components/inputs/CustomMultiSelect";
import ButtonWithIcon from "@/assets/components/widget/ButtonWithIcon";
import moment from "moment";
import {LARAVEL_DATE_FORMAT, MOMENT_SYSTEM_DATE_FORMAT} from "@/core/config/constant";
import SampleIndexNavPanel from "@/view/pages/samples/layout/SampleIndexNavPanel";
import DashboardBox from "@/assets/components/DashboardBox";
import QRCode from "@/assets/components/widget/QRCode";
import PhotoRecorder from "@/assets/components/recorder/PhotoRecorder";
import FileInput from "@/assets/components/inputs/FileInput";

export default {
  name: "NewEscEntry",
  components: {
    SampleIndexNavPanel,
    InputFilter,
    TextInput,
    DatePickerInput,
    MultipleSelectInput,
    SelectInput,
    CustomMultiSelect,
    ButtonWithIcon,
    NumberInput,
    DashboardBox,
    QRCode,
    PhotoRecorder,
    FileInput
  },
  computed: {
    ...mapGetters({
      loading: LOADING
    }),
    totalAmount() {
      let temp = [...this.form.expenses, ...this.form.optionalExpenses], total = 0;
      for (let i = 0; i < temp.length; i++) {
        if (!isNaN(Number(temp[i].amount))) {
          total += Number(temp[i].amount)
        }
      }
      return (total).toFixed(2);
    },
    fabricAndExpenseTotal() {
      let total = 0;
      this.form.fabric_calculations.forEach(fabric => {
        total += (Number(fabric.consumption) * Number(fabric.price))
      });

      return Number(total + Number(this.totalAmount)).toFixed(2);
    },
    generalTotalAmount() {
      let total = 0;
      for (let i = 0; i < this.form.fabric_calculations.length; i++) {
        this.form.fabric_calculations[i].total = Number(this.form.fabric_calculations[i].price) * Number(this.form.fabric_calculations[i].consumption);
        total += this.form.fabric_calculations[i].total;
      }
      total = (Number(total) + Number(this.totalAmount));
      total = total * (1 + ((this.escConfigurations.wastage / 100) + (this.escConfigurations.exchange_difference / 100) + (Number(this.form.dividend) / 100)));

      return Number(total).toFixed(2);
    },
    escConfigurations() {
      let settings = {
        dividend: 0,
        exchange_difference: 0,
        wastage: 0,
      };
      if (this.generalSetting.length) {
        settings = _.first(this.generalSetting)['esc_configurations'];

        if (this.form) {
          this.form.dividend = settings.dividend;
        }
      }
      return settings;
    },
    isFormValid() {
      if (Number(this.form.supplier_company_id) === 0) return false;
      if (!this.form.responsible_person) return false;
      if (!this.form.reference_code) return false;
      if (!this.form.composition) return false;
      if (!this.form.width) return false;
      if (!this.form.quality) return false;
      if (!this.form.weight) return false;
      if (this.form.image_link === null && this.form.image === null) return false;

      return true;
    },
  },
  data() {
    return {
      imageLinks: [],
      imageIndex: null,
      isSubmitting: false,
      today: moment(),

      // Options
      supplierCompanyOptions: [],
      id: this.$route.params.id,
      defaultForm: {
        id: null,
        supplier_company_id: null,
        image: null,
        image_link: null,
        reference_code: null,
        description: null,
        composition: null,
        date: moment().format(MOMENT_SYSTEM_DATE_FORMAT),
        responsible_person: null,
        collection_number: null,
        width: null,
        weight: null,
        quality: null,
        is_submitted: false,
        pdf_link: null,
      },
      form: {
        id: null,
        supplier_company_id: null,
        image: null,
        image_link: null,
        reference_code: null,
        description: null,
        composition: null,
        date: moment().format(MOMENT_SYSTEM_DATE_FORMAT),
        responsible_person: null,
        collection_number: null,
        width: null,
        weight: null,
        quality: null,
        is_submitted: false,
        pdf_link: null,
      }
    }
  },
  methods: {
    getOriginalImage() {
      this.imageLinks = [this.form.image_link_large];
      this.imageIndex = 0;
    },
    cancelDocumentUpload() {
      this.form.image = null;
      this.closeUploadDocumentModal();
    },
    closeUploadDocumentModal() {
      if (this.form.image) {
        const img = document.getElementById(`image_holder`);
        if (img) {
          img.src = URL.createObjectURL(this.form.image)
        }
      }
      this.$modal.hide('upload-new-image');
    },
    openUploadDocumentModal() {
      this.$modal.show('upload-new-image');
    },
    imageChange() {
      this.form.image = this.$refs.file.files[0];
      let imageHolder = $('#image_holder');
      imageHolder.attr('src', URL.createObjectURL(this.form.image));
    },
    submitForm() {
      if (this.isSubmitting) return;
      this.isSubmitting = true;
      this.sweetAlertLoading();
      let self = this;
      let form = new FormData();
      let submitForm = this.cloneForce(this.form);
      form.append('supplier_company_id', submitForm.supplier_company_id);

      if (submitForm.image) {
        form.append('image', this.form.image);
      }
      form.append('reference_code', submitForm.reference_code);
      form.append('composition', submitForm.composition);
      form.append('responsible_person', submitForm.responsible_person);
      form.append('width', submitForm.width);
      form.append('weight', submitForm.weight);
      form.append('quality', submitForm.quality);

      if (submitForm.description) {
        form.append('description', submitForm.description);
      }
      let payload = {
        url: submitForm.id === null ? 'api/time-sheets' : 'api/time-sheets/' + submitForm.id,
        id: this.id,
        contents: form,
      }
      this.$store.dispatch(CREATE_ITEM, payload).then(result => {

        self.sweetAlertClose();
        if (result.status) {
          self.sweetAlertSuccess(self.$t('general.successfully_created'));
          self.form.id = result.data.id;
          self.form.collection_number = result.data.collection_number;
          self.form.is_submitted = true;
          self.form.pdf_link = result.data.pdf_link;
          self.isSubmitting = false;
        } else {
          self.isSubmitting = false;
          self.form.is_submitted = false;
          let response = result.data.response;
          if (response.hasOwnProperty('data') && response.data.hasOwnProperty('errors')) {
            let messageArray = Object.values(response.data.errors)[0]
            self.sweetAlertError(messageArray[0]);
          }
          else if (response.hasOwnProperty('data') && response.data.hasOwnProperty('message')) {
            self.sweetAlertError(response.data.message);
          } else {
            self.sweetAlertError(response.data);
          }
        }
      });
    },
    deleteItem() {
      let self = this;

      if(!this.isUserGranted('TimeSheet', ['delete'])) {
        return false;
      }

      let configs = {
        imageUrl: this.getIconByKey('icons.time_sheet.delete_time_sheet'),
      }
      let message = this.form.collection_number + " " + this.$t('time_sheet.are_you_sure_delete');
      this.sweetAlertConfirm(message, null, null, configs).then((reply) => {
        if (reply) {
          self.$store.dispatch(DELETE_ITEM_BY_ID, {
            url: `api/time-sheets/${self.form.id}`,
            acceptPromise: true,
          }).then((result) => {
            if (result.status) {
              self.sweetAlertSuccess(self.$t('general.successfully_deleted')).then(() => {
                self.$router.go(-1);
              })
            }
          });
        }
      })
    },
  },
  mounted() {
    let self = this;
    let promises = [];

    if(!this.isUserGranted('TimeSheet', ['view', 'update', 'create'],)) {
      return false;
    }

    this.sweetAlertLoading();
    promises.push(this.$store.dispatch(REST_GET_ITEM, {
      url: 'api/supplier-companies',
      acceptPromise: true,
      filters: {}
    }));
    if (self.id != null) {
      promises.push(self.$store.dispatch(GET_ITEM_DETAIL_BY_ID, {
        url: 'api/time-sheets/' + this.id,
        acceptPromise: true
      }));
    }
    Promise.all(promises).then(results => {
      if (results[0].status) {
        let temp = results[0].data;
        let returnValues = {};
        for (let i = 0; i < temp.length; i++) {
          {
            returnValues[temp[i].id] = temp[i].name;
          }
        }
        self.supplierCompanyOptions = returnValues;
      }

      if (self.id != null) {
        if (results[1].status) {
          self.form = results[1].data;
          self.form.date=moment(self.form.date,LARAVEL_DATE_FORMAT).format(MOMENT_SYSTEM_DATE_FORMAT)
          self.form.is_submitted = true;
        }
      }
      self.sweetAlertClose();
    })

    this.$store.dispatch(SET_BREADCRUMB, [
      {title: this.$t("time_sheet.self"), route: "time_sheet.index" },
      {title: this.id ? this.$t("time_sheet.time_sheet_edit") : this.$t("time_sheet.time_sheet_create")}
    ]);
  },
}


</script>
<style scoped>
.firstTable1 {
  border-top-left-radius: 9px;
}

table, td, th {
  border: 1px solid #ddd;
  text-align: left;
}

#mainDiv {
  border-radius: 10px;
}

table {
  border-collapse: collapse;
  width: 100%;
  border-radius: 4px;
}

th, td {
  padding: 15px;
}

#filter-card {
  background-color: transparent !important;
  border: none;
  box-shadow: none;
}

*:focus {
  outline: none;
}
</style>
